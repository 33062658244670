@font-face {
  font-family: "SF PRO DISPLAY";
  src: url("../public/fonts/SF-Pro-Display-Regular.ttf");
  font-style: normal; /* Adjust if needed */
}

body {
  margin: 0;
  padding: 0 I !important; /* added for ads remove padding and scrollbar issue */
  /* background-color: #fafafa; */
  font-family: "SF PRO DISPLAY";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* p{
  margin-bottom: 10px !important;
} */
.ql-align-justify {
  line-height: 23px !important;
  letter-spacing: 0.2px;
  font-family: "SF PRO DISPLAY";
  font-size: 15px;
  margin-bottom: 10px !important;
}
.ql-editor {
  font-family: "SF PRO DISPLAY";
}
.MuiMenu-paper {
  box-shadow: 0px 0px 10px 0 #0000004d !important;
}
/* #alert-dialog-title, #alert-dialog-description, .MuiDialogContent-root{
  padding: 0 !important;
} */
.note p,
.ql-editor p {
  margin-bottom: 10px !important;
}
.note-details {
  display: -webkit-box;
  -webkit-line-clamp: 5 !important;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.1 !important;
  line-break: auto;
}
.note-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin: 5px 0 10px 0; */
  text-transform: capitalize;
  letter-spacing: 0.8px;
  line-height: 25px !important;
}
.note-details > h1,
.note-details > h2,
.note-details > h3,
.note-details > h4,
.note-details > h5,
.note-details > h6,
.ql-snow .ql-editor h1,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor h5,
.ql-snow .ql-editor h6 {
  text-transform: capitalize;
  letter-spacing: 0.8px;
  line-height: 25px !important;
  font-size: 18px !important;
  font-weight: 600;
  color: #515151;
  margin-bottom: 3px !important;
  letter-spacing: 0.8px;
}
.loginbackground_img {
  background: url("./assets/images/login_bg.svg") no-repeat center center;
  background-size: cover;
  width: 100%;
  /* height: 100%; */
  /* position: absolute;
  z-index: -1; */
}

.ql-font {
  color: #505c7b !important;
}

.ql-container {
  border: none !important;
  background: transparent !important;
  height: auto !important;
  max-width: 100% !important;
  font-size: 15px !important;
}

.ql-snow.ql-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  background: transparent;
}

.login-gradient {
  background: rgb(106, 183, 253);
  background: -moz-linear-gradient(
    90deg,
    rgba(106, 183, 253, 1) 0%,
    rgba(4, 225, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(106, 183, 253, 1) 0%,
    rgba(4, 225, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(106, 183, 253, 1) 0%,
    rgba(4, 225, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6ab7fd",endColorstr="#04e1ff",GradientType=1);
}

.background_img {
  background-image: url("./assets/images/client_bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 100%;
  height: 85%;
  position: absolute;
  right: 1.5%;
  bottom: 0;
}

.attachment-icon {
  width: 40px;
  height: 40px;
}

:root {
  --primary: rgba(215, 240, 255, 1);
  --secondary: rgba(226, 215, 255, 1);
  --disable: rgba(190, 190, 190, 1);
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--secondary) !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    var(--primary),
    rgba(4, 225, 255, 1)
  ) !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    90deg,
    var(--primary),
    rgba(4, 225, 255, 1)
  ) !important;
}

/* To hide the scrollbar for a specific class */
.dashbard-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer */
}

.dashbard-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.carousel-indicator {
  width: 8px !important;
  height: 8px !important;
  margin: 0 4px !important; /* Adjust spacing between dots */
  padding: 0 !important; /* Ensure no padding is added */
  border-radius: 50%; /* Ensure dots are circular */
}

.carousel-indicator-active {
  width: 8px !important;
  height: 8px !important;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: 0.5s ease;
}

.text {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* landing page */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: Inter;
  overflow-x: hidden;
}

/* navbar */
.navbar-nav,
.nav-link {
  color: #2c2b4b !important;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
}

.nav-item {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link:active,
.nav-link.active {
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
  color: #6ab7fd !important;
}

.btn-primary {
  border-radius: 10px;
  background: #6ab7fd;
  color: #ffffff;
  border: none;
}

.btn-primary:hover {
  background: #6abefdc2;
  transition: 0.3s ease-in-out;
}

.sec-3,
.sec-4,
.sec-5,
.sec-6,
.sec-7 {
  padding: 4% 0;
}

/* hero-banner */
.hero-banner {
  background-image: url("./assets/LandingPage/hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-img {
  width: 49vw;
  height: 88vh;
}

.hero-heading {
  color: #18063c;
  font-weight: 700;
  line-height: 70px;
  text-transform: capitalize;
  font-size: 47px;
}

.hero-content {
  color: #656565;
  font-size: 18px;
  letter-spacing: 0.3px;
}

/* counter sec */
.counter-sec {
  background-image: url("./assets/LandingPage/counter-bg.svg");
  color: #ffffff;
  height: 230px;
  padding: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.counter-sec h2 {
  font-weight: 700;
}

/* about sec */
.about-list span {
  color: #222429;
  font-size: 17px;
  font-weight: 600;
  line-height: 33.75px;
}

/* shopping sec */
.shopping-heading,
.notes-heading,
.feature-heading,
.enotes-heading,
.contact-heading,
.about-heading {
  color: #000;
  font-size: 44px;
  font-weight: 700;
  line-height: 65px;
  text-transform: capitalize;
}

.enotes-heading,
.contact-heading,
.about-heading {
  text-transform: none;
}

.shopping-heading span,
.notes-heading span,
.feature-heading span,
.enotes-heading span,
.about-heading span {
  background-color: #fff3dd;
}

.shopping-content p,
.notes-content p,
.feature-content p,
.enotes-heading p,
.contact-content p,
.about-content p,
footer p {
  color: #656565;
  line-height: 30px;
  font-size: 1.1rem;
}

.contact-content p,
.about-content p,
footer p {
  font-size: 1rem;
}

.shopping-list {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 1px 64.093px 0px #5f575729, 2px 4px 20px 0px #e0fad980;
}

.iconbox {
  background: #ffffff;
  box-shadow: 1px 1px 10px 0px #373b3e61;
  padding: 10px;
  margin: 16px 0;
  border-radius: 10px;
  width: 46px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconbox img {
  display: block;
  margin: 0 auto;
  width: 20px;
}

/* enote sec css */
.enote-card {
  box-shadow: 4px 4px 20px 0px #cdcdcd45;
  background: #ffffff;
  border-radius: 16px;
  border: none;
  margin: 13px 0;
  display: flex;
  flex-direction: column;
  min-height: 230px;
  padding: 20px !important;
}
.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 300px !important;
}

.enote-card p {
  color: #656565;
  margin-bottom: 0;
}

.enote-card h1 {
  color: #000;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}
.enote-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.enoteimg-sec {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.enotecard-content {
  min-height: 70px;
  margin: 1rem 0;
}
.card-1:hover {
  background-color: #ff578f30;
  border: 1px solid #ff578f;
  box-shadow: 4px 4px 20px 0px #ff578f30;
}
.card-2:hover {
  background-color: #f8705b30;
  border: 1px solid #fc8e77;
  box-shadow: 4px 4px 20px 0px #f8705b30;
}
.card-3:hover {
  background-color: #4585ff30;
  border: 1px solid #3f9afd;
  box-shadow: 4px 4px 20px 0px #4585ff30;
}
.card-4:hover {
  background-color: #b5e14e30;
  border: 1px solid #b5e14e;
  box-shadow: 4px 4px 20px 0px #b5e14e30;
}
.card-5:hover {
  background-color: #ac7aff30;
  border: 1px solid #ac7aff;
  box-shadow: 4px 4px 20px 0px #ac7aff30;
}
.card-6:hover {
  background-color: #57bfea30;
  border: 1px solid #57bfea;
  box-shadow: 4px 4px 20px 0px #57bfea30;
}
.card-7:hover {
  background-color: #ee9be230;
  border: 1px solid #ee9be2;
  box-shadow: 4px 4px 20px 0px #ee9be230;
}
.card-8:hover {
  background-color: #62d2da30;
  border: 1px solid #62d2da;
  box-shadow: 4px 4px 20px 0px #62d2da30;
}
.card-9:hover {
  background-color: #8afcc830;
  border: 1px solid #8afcc8;
  box-shadow: 4px 4px 20px 0px #8afcc830;
}
.card-10:hover {
  background-color: #fd978030;
  border: 1px solid #fd9780;
  box-shadow: 4px 4px 20px 0px #fd978030;
}

/* contact section */
.contact-banner {
  background-image: url("./assets/LandingPage/contact-banner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 460px;
  border-radius: 16px;
}

.contact-form .form-control {
  background: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 14px 18px;
}

.contact-banner .btn-primary {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
}

/* objects css */
.sec-2::after,
.sec-7::after {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 40px;
  height: 135px;
  background-image: url("./assets/LandingPage/halfsquare-object-2.svg");
  background-size: cover;
  background-position: center;
}

.sec-3::before {
  content: "";
  position: absolute;
  bottom: 70px;
  left: 1%;
  width: 72px;
  height: 40px;
  background-image: url("./assets/LandingPage/Union-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-3::after {
  content: "";
  position: absolute;
  bottom: 40%;
  right: 0;
  width: 67px;
  height: 135px;
  background-image: url("./assets/LandingPage/halfgrrencircle-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-4::before {
  content: "";
  position: absolute;
  bottom: 2%;
  left: 0%;
  width: 52px;
  height: 180px;
  background-image: url("./assets/LandingPage/halfsquare-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-4::after,
.sec-8::after {
  content: "";
  position: absolute;
  bottom: 32%;
  right: 0;
  width: 80px;
  height: 135px;
  background-image: url("./assets/LandingPage/squaredot-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-5::after {
  content: "";
  position: absolute;
  bottom: 46%;
  right: 0;
  width: 170px;
  height: 40px;
  background-image: url("./assets/LandingPage/Union-object2.svg");
  background-size: cover;
  background-position: center;
}

.sec-6::before {
  content: "";
  position: absolute;
  bottom: 38px;
  left: 0;
  width: 185px;
  height: 75px;
  background-image: url("./assets/LandingPage/trainglewave-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-6::after {
  content: "";
  position: absolute;
  bottom: 40%;
  right: 0;
  width: 66px;
  height: 132px;
  background-image: url("./assets/LandingPage/halfgrrencircle-object.svg");
  background-size: cover;
  background-position: center;
}

.sec-8::before {
  content: "";
  position: absolute;
  bottom: 93%;
  left: 0%;
  width: 65px;
  height: 77px;
  background-image: url("./assets/LandingPage/smallsquare-object.svg");
  background-size: cover;
  background-position: center;
}

.scroll-container {
  /* max-width: 380px;
  white-space: nowrap;
  overflow-x: scroll; */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.side-drawer {
  width: 100% !important;
  max-width: 280px !important;
}
.feedback-modal,
.setpin-modal,
.myprofile-modal,
.unlock-modal {
  max-width: 460px;
  width: 100%;
}
.drawing-modal {
  max-width: 800px;
  width: 100%;
}
/* dailog modals */
.dialog-modals,
.dialog-actions {
  display: flex;
  justify-content: center !important;
  align-items: center;
  text-align: center;
}
.dialog-actions {
  margin-top: 7px;
}
.dialog-container {
  padding: 1.3rem !important;
}
.dialog-title {
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  line-height: 24px !important;
  padding: 0px 10px !important;
  color: #2c2b4b !important;
}
.dialog-content p {
  font-size: 15px !important;
  line-height: 21px !important;
  letter-spacing: 0.6px !important;
  padding: 10px 10px !important;
  color: #2c2b4b !important;
  font-weight: 500;
}
.dialog-text {
  font-size: 18px !important;
  line-height: 25px !important;
  padding: 10px 10px 0 10px !important;
  color: #2c2b4b !important;
  font-weight: 600;
}
.dialog-content {
  padding: 0 !important;
}
.dialog-image {
  margin-bottom: 10px !important;
}
.dialog-colors {
  padding: 1rem !important;
  margin-top: 10px;
}
.btn {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.8px;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  transition: 0.3s ease-in-out !important;
  width: 100%;
  font-family: "SF PRO DISPLAY" !important;
}
.cancel-btn {
  background: transparent !important;
  color: #c2c3ca !important;
  border: 1px solid #56678942 !important;
}
.logout-btn {
  background: #6ab7fd !important;
  color: #fff !important;
  border: none !important;
}
.cancel-btn:hover {
  background: #6ab7fd !important;
  color: #fff !important;
  border: 1px solid #6ab7fd !important;
}
.logout-btn:hover {
  background: rgba(106, 182, 253, 0.8) !important;
  color: #fff !important;
  border: none !important;
}
/* media query */
@media only screen and (min-width: 1500px) and (max-width: 1870px) {
  .sec-3::after,
  .sec-6::after {
    width: 50px;
    height: 100px;
  }

  .sec-2::after,
  .sec-7::after {
    width: 30px;
    height: 100px;
  }

  .sec-4::before {
    bottom: 0%;
    width: 42px;
    height: 149px;
  }

  .sec-4::after,
  .sec-8::after {
    bottom: 28%;
    width: 60px;
    height: 100px;
  }

  .sec-5::after {
    width: 100px;
    height: 26px;
  }

  .hero-heading {
    font-size: 44px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 40px;
    line-height: 60px;
  }
  .enote-card {
    min-height: 160px;
  }
}

@media only screen and (max-width: 1440px) {
  .unlock-modal,
  .feedback-modal,
  .setpin-modal,
  .myprofile-modal,
  .changepassword-modal {
    max-width: 400px;
    width: 100%;
  }
  .drawing-modal {
    max-width: 700px;
    width: 100%;
  }
  .container {
    max-width: 1170px;
  }

  .hero-heading {
    font-size: 42px;
    line-height: 60px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 38px;
    line-height: 56px;
  }

  .shopping-content p,
  .notes-content p,
  .feature-content p,
  .enotes-heading p,
  .contact-content p,
  .about-content p,
  footer p {
    font-size: 1rem;
  }

  .Feature-img,
  .shoppinglist-img,
  .mynotes {
    justify-content: center !important;
  }

  .Feature-img img,
  .shoppinglist-img img,
  .mynotes img {
    width: 80%;
  }

  .sec-5::after {
    bottom: 37%;
    width: 120px;
    height: 29px;
  }

  .sec-4::after,
  .sec-8::after {
    width: 68px;
    height: 115px;
  }
}

@media only screen and (max-width: 1366px) {
  .side-drawer {
    width: 100% !important;
    max-width: 280px !important;
  }
  .masonry {
    --masonry-box-width: 260px !important;
  }
  .hero-heading {
    font-size: 34px;
    line-height: 50px;
  }

  .hero-content,
  .about-list span {
    font-size: 1rem;
  }

  .google-icons img {
    width: 35%;
  }

  .counter-sec {
    height: auto;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 31px;
    line-height: 42px;
  }

  .Feature-img,
  .shoppinglist-img,
  .mynotes {
    justify-content: end !important;
  }

  .Feature-img img,
  .shoppinglist-img img,
  .mynotes img {
    width: 90%;
  }

  .enote-card .h5 {
    font-size: 18px;
  }

  /* .enote-card {
    min-height: 162px;
    padding: 25px 0 !important;
  } */

  .enote-card p,
  .nav-link,
  .counter-sec p {
    font-size: 14px;
  }

  .contact-banner .btn-primary {
    font-size: 17px;
    line-height: 20px;
  }

  .sec-6::before {
    width: 132px;
    height: 55px;
  }

  .sec-6::after {
    width: 50px;
    height: 100px;
  }

  .sec-2::after,
  .sec-7::after {
    width: 30px;
    height: 100px;
  }

  .sec-4::before {
    width: 35px;
    height: 120px;
  }

  .sec-3::before {
    width: 65px;
    height: 30px;
  }

  .sec-3::after {
    width: 60px;
    height: 120px;
  }
}

@media only screen and (max-width: 1200px) {
  .hero-heading {
    font-size: 30px;
    line-height: 42px;
  }

  .hero-content,
  .about-list span,
  .shopping-content p,
  .notes-content p,
  .feature-content p,
  .enotes-heading p,
  .contact-content p,
  .about-content p,
  footer p {
    font-size: 14px;
    line-height: 23px;
  }

  .counter-sec {
    padding: 52px;
  }

  .enote-card p,
  .nav-link,
  .counter-sec p {
    font-size: 13px;
  }

  /* .enote-card {
    min-height: 140px;
    padding: 16px 0 !important;
  } */

  .enote-card .h5 {
    font-size: 16px;
  }

  .contact-form .form-control {
    padding: 10px 18px;
    font-size: 12px;
  }

  .contact-banner .btn-primary {
    font-size: 16px;
    line-height: 16px;
  }

  .sec-5::after {
    width: 90px;
    height: 23px;
  }

  .enote-card img {
    width: 40px;
  }

  /* .enote-card {
    min-height: auto;
  } */
}

@media only screen and (max-width: 992px) {
  .search-bar {
    width: 72% !important;
  }
  .note-details {
    max-height: 120px !important;
  }
  .bottom-menu-bar {
    position: fixed !important;
    bottom: 10px !important;
  }
  .side-drawer {
    width: 100% !important;
    max-width: 280px !important;
  }
  .nav {
    background-color: #f8f9fa;
  }

  .hero-heading {
    font-size: 26px;
    line-height: 38px;
  }

  .hero-img {
    height: 40vh;
  }

  .counter-sec {
    /* padding: 34px 40px; */
    background-position: unset;
  }

  .counter-sec h2 {
    font-size: 1.3rem;
  }

  .counter-sec p {
    font-size: 12px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 23px;
    line-height: 35px;
  }

  .contact-banner {
    height: 550px;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
}

/* .drawer {
  width: 200px !important;
} */

@media only screen and (max-width: 820px) {
  .drawing-modal {
    max-width: 500px;
    width: 100%;
  }
  .counter-sec {
    padding: 34px;
  }

  .hero-heading {
    font-size: 22px;
    line-height: 35px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 20px;
    line-height: 33px;
  }

  .sec-3::after {
    width: 52px;
    height: 103px;
  }
}

@media only screen and (max-width: 550px) {
  .tick-btn {
    width: 22px !important;
    height: 22px !important;
    padding: 2px !important;
  }
  .MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto !important;
  }
  .unlock-modal,
  .feedback-modal,
  .setpin-modal,
  .myprofile-modal,
  .changepassword-modal {
    width: 85%;
  }
  .drawing-modal {
    width: 88%;
  }
  .icon-btn-placement {
    right: 15px !important;
  }
  .side-drawer {
    width: 100% !important;
    max-width: 245px !important;
  }
  .textfield-title {
    min-height: auto !important;
    margin-top: 5rem !important;
    margin-bottom: 0 !important;
    max-width: 88% !important;
  }
  .tag-model {
    width: 300px !important;
  }
  .nav-item {
    padding: 5px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 25px;
    line-height: 35px;
  }

  .hero-img {
    height: 49vh;
    width: 100%;
  }

  .hero-sec {
    padding-top: 15%;
  }

  .hero-heading {
    font-size: 30px;
    line-height: 44px;
  }

  .shopping-sec,
  .feature-sec {
    flex-direction: column-reverse;
  }

  .Feature-img img,
  .shoppinglist-img img,
  .mynotes img {
    width: 100%;
  }

  .contact-banner {
    height: 742px;
  }

  .about-content,
  .mynotes-content,
  .features {
    padding-top: 40px;
  }

  .about-list {
    margin-top: 0;
  }

  .counter-sec {
    background-image: none;
    background-color: #6ab7fd;
    border-radius: 16px;
  }

  .counter-sec .col-12,
  .counter-sec {
    padding: 20px 0 !important;
  }

  .sec-3::after {
    width: 37px;
    height: 74px;
  }

  .sec-3::before {
    width: 49px;
    height: 23px;
    bottom: -33px;
  }

  .sec-4::after,
  .sec-8::after {
    width: 48px;
    height: 81px;
  }

  .sec-4::before {
    width: 23px;
    height: 79px;
    bottom: -20px;
  }

  .sec-6::before {
    width: 99px;
    height: 40px;
    bottom: 15px;
  }

  .sec-2::after {
    width: 21px;
    height: 73px;
    bottom: -8px;
  }

  .sec-7::after {
    width: 25px;
    height: 86px;
    top: 53px;
  }

  .sec-8::before {
    bottom: 97%;
    width: 41px;
    height: 47px;
  }
}

@media only screen and (max-width: 440px) {
  .hero-heading {
    font-size: 25px;
    line-height: 37px;
  }

  .hero-img {
    height: 30vh;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 22px;
    line-height: 34px;
  }

  .contact-banner {
    height: 830px;
  }
}

@media only screen and (max-width: 420px) {
  .side-drawer {
    width: 100% !important;
    max-width: 220px !important;
  }
  .hero-content,
  .about-list span,
  .shopping-content p,
  .notes-content p,
  .feature-content p,
  .enotes-heading p,
  .contact-content p,
  .about-content p,
  footer p {
    font-size: 13px;
    line-height: 23px;
  }

  .hero-heading {
    font-size: 22px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 20px;
  }

  .contact-banner .btn-primary {
    font-size: 14px;
    line-height: 12px;
  }

  .sec-6::after {
    width: 40px;
    height: 80px;
  }
}
@media only screen and (max-width: 400px) {
  /* .loginbackground_img{
    height: 950px;
    min-height: 100vh;
  } */
  .qrlogin {
    width: 250px !important;
  }
}

@media only screen and (max-width: 375px) {
  .hero-heading {
    font-size: 21px;
  }

  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 19px;
  }

  .sec-4::after,
  .sec-8::after {
    width: 40px;
    height: 70px;
    top: 47%;
  }

  .enote-card p,
  .nav-link,
  .counter-sec p {
    font-size: 12px;
  }

  .hero-content,
  .about-list span,
  .shopping-content p,
  .notes-content p,
  .feature-content p,
  .enotes-heading p,
  .contact-content p,
  .about-content p,
  footer p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .shopping-heading,
  .notes-heading,
  .feature-heading,
  .enotes-heading,
  .contact-heading,
  .about-heading {
    font-size: 18px;
    line-height: 27px;
  }

  .hero-heading {
    font-size: 20px;
  }
}
/* landing page finish */

/* .masonry {
  --masonry-gap: 1rem;
  --masonry-box-width: 280px;
  column-gap: var(--masonry-gap);
  column-fill: initial;
  column-width: var(--masonry-box-width);
}

.masonry > * {
  break-inside: avoid;
  margin-bottom: var(--masonry-gap);
} */

/* @supports (grid-template-rows: masonry) {
  .masonry {
    display: grid;
    gap: var(--masonry-gap);
    grid-template-rows: masonry;
    grid-template-columns: repeat(auto-fill, minmax(var(--masonry-box-width), 1fr));
    align-tracks: stretch;
  }

  .masonry > * {
    margin-bottom: initial;
  }
} */

/* .masonry {
  padding: 2rem 0;
} */

.ql-container {
  /* height: 500px !important; */
  /* max-height: 1000px !important; */
  padding-top: 5px !important;
  padding-bottom: 20px !important;
  /* margin-bottom: 40px !important; */
}
.ql-toolbar {
  background-color: transparent !important;
  margin-top: -1px !important;
  height: 40px !important;
}
.ql-editor {
  background-color: transparent !important;
}

/* shake field custom class */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.3s ease;
}

/* @media (max-width: 1920px) {
  .ql-container {
    height: 700px !important;
  }
}

@media (max-width: 1650px) {
  .ql-container {
    height: 660px !important;
  }
}

@media (max-width: 1440px) {
  .ql-container {
    height: 340px !important;
  }
}

@media (max-width: 1024px) {
  .ql-container {
    height: 300px !important;
  }
}

@media (max-width: 768px) {
  .ql-container {
    height: 260px !important;
  }
}

@media (max-width: 480px) {
  .ql-container {
    height: 200px !important;
  }
} */
@media (min-width: 1851px) {
  .ql-editor {
    max-height: 70vh !important;
  }
}
@media (max-width: 1850px) {
  .ql-editor {
    max-height: 70vh !important;
  }
}
@media (max-width: 1650px) {
  .ql-editor {
    max-height: 65vh !important;
  }
}

@media (max-width: 1440px) {
  .ql-editor {
    max-height: 60vh !important;
  }
}

.public-note .ql-editor {
  /* max-height: 100vh !important; */
  max-height: fit-content !important;
  overflow-y: scroll;
  scrollbar-width: none;
}
.ql-container.ql-disabled .ql-tooltip {
  display: none;
}
